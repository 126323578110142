import classes from './InputSlider.module.css';
import Slider from 'rc-slider';
import React, { FC } from 'react';
import useInputSlider from './useInputSlider';
import { MultiRangeSliderProps, sliderRangeProps } from '../../types';
import { ToLocalStringConvert } from '../../Utils/toLocalStringConvert';

interface InputRangeSliderProps extends MultiRangeSliderProps {
  keyName: string;
  step?: number;
  rangeValue?: string[];
  handleFilterType: (value: string, key: string) => void;
  setChangeMinMaxValue: (value: boolean) => {
    type: string;
    payload: boolean;
  };
  setBackToSelectDiamondPage?: (value: boolean) => {
    type: string;
    payload: boolean;
  };
  isCustomSlider?: boolean;
  sliderRange?: sliderRangeProps[];
}

const InputRangeSlider: FC<InputRangeSliderProps> = (props) => {
  const {
    step,
    keyName,
    isShowDollar,
    isCustomSlider = false
  } = props;

  const {
    value,
    isLeftFocus,
    isRightFocus,
    handleChange,
    handleRightFocus,
    handleLeftFocus,
    handleLeftInputRange,
    handleRightInputRange,
    handleChangeComplete,
    handleBlur,
    minValue,
    maxValue,
    handleContextMenu,
    sliderValue
  } = useInputSlider(props);

  return (
    <>
      <div className={`${classes.inputRangeSlider} ${minValue === maxValue ? classes.minMaxSame : ''}`}
           onContextMenu={handleContextMenu} style={{ userSelect: 'none' }}>
        <Slider
          range
          allowCross={false}
          min={minValue as number}
          max={maxValue as number}
          value={isCustomSlider ? sliderValue as number[] : value as number[]}
          step={step}
          draggableTrack={false}
          handleStyle={{ borderColor: '#4a4a4a', backgroundColor: '#fff' }}
          trackStyle={{ backgroundColor: '#4a4a4a' }}
          onChange={handleChange}
          onChangeComplete={(val) => handleChangeComplete(val, keyName)}
        />
        {value ? (
          <div className={classes.slider_inputs}>
            <div className={classes.slider_inputs_inner}>
              <label style={{
                visibility: 'hidden',
                opacity: 0,
                maxHeight: 0,
                maxWidth: 0,
                display: 'inline-flex',
                overflow: 'hidden'
              }} htmlFor={`input_slider_left_${keyName}`}>{value[0]}</label>
              <input
                className={classes.slider_leftValue}
                id={`input_slider_left_${keyName}`}
                onFocus={isShowDollar ? handleLeftFocus : () => {
                }}
                onBlur={() => handleBlur(0, keyName)}
                type={'text'}
                onKeyDown={(event) => {
                  if (event.target instanceof HTMLElement && event.key === 'Enter') {
                    handleBlur(0, keyName);
                  }
                }}
                onKeyUp={(event) => {
                  if (event.target instanceof HTMLElement && (event.keyCode === 9 || event.key === 'Tab')) {
                    event.currentTarget.select();
                  }
                }}
                value={isLeftFocus ? `${ToLocalStringConvert(value[0])}` : !isLeftFocus && isShowDollar ? `$${ToLocalStringConvert(+value[0])}` : ToLocalStringConvert(value[0])}
                onChange={(e) => handleLeftInputRange(e)}
              />
            </div>
            <div className={classes.slider_inputs_inner}>
              <label style={{
                visibility: 'hidden',
                opacity: 0,
                maxHeight: 0,
                maxWidth: 0,
                display: 'inline-flex',
                overflow: 'hidden'
              }}
                     htmlFor={`input_slider_right_${keyName}`}>{value[1]}</label>
              <input
                type={'text'}
                id={`input_slider_right_${keyName}`}
                onFocus={isShowDollar ? handleRightFocus : () => {
                }}
                onBlur={() => handleBlur(1, keyName)}
                onKeyDown={(event) => {
                  if (event.target instanceof HTMLElement && event.key === 'Enter') {
                    handleBlur(1, keyName);
                  }
                }}
                onKeyUp={(event) => {
                  if (event.target instanceof HTMLElement && (event.keyCode === 9 || event.key === 'Tab')) {
                    event.currentTarget.select();
                  }
                }}
                className={classes.slider_rightValue}
                value={isRightFocus ? `${ToLocalStringConvert(value[1])}` : (!isRightFocus && isShowDollar ? `$${ToLocalStringConvert(+value[1])}` : ToLocalStringConvert(value[1]))}
                onChange={(e) => handleRightInputRange(e)}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default InputRangeSlider;
