// reducers/counter.ts
import FilterConstant from '../constant/FilterConstant';
import { FilterAction, FilterState } from '../types';

const initialFilterState: FilterState = {
    options: {},
    isReset: false,
    minMaxValue:{},
    removedMinMax:{},
    afterRingSelectedOptions :{},
    isMinMaxChangeValue: true,
    sliderRange: {}
};

const filterReducer = (state = initialFilterState, action: FilterAction): FilterState => {
    switch (action.type) {
        case FilterConstant.SET_FILTER:
            return {
                ...state,
                isReset:false,
                options: {
                    ...state.options,
                    [(action.payload as { key: string; value: string| string[] }).key]: (action.payload as { key: string; value:  string| string[] }).value
                }
            };
        case FilterConstant.REMOVE_FILTER_KEY:
            delete state.options[(action.payload as { key: string; value: string| string[] }).key]
            return {
                ...state,
                options: {...state.options},
                isReset:false
            };
        case FilterConstant.RESET_FILTER_KEY:
            return {...initialFilterState ,isReset : true};
        case FilterConstant.FILTER_MIN_MAX_PROPERTY:
            return {
                ...state,
                minMaxValue : (action.payload as {[key: string]: {[key:string] : number}})
            };
      case FilterConstant.FILTER_SLIDER_RANGE:
        return {
          ...state,
          sliderRange: action.payload as any
        };
        case FilterConstant.SET_MIN_MAX_VALUE_CHANGE:
            return {
                ...state,
                isMinMaxChangeValue : action.payload as boolean
            };
        case FilterConstant.SET_AFTER_RING_OPTIONS :
            return {
                ...state,
                afterRingSelectedOptions : action.payload as {[key:string] : string | string[]},
                options : {...state.options , ...action.payload as {[key:string] : string | string[]}}
            }
        case FilterConstant.FILTER_INITIAL_STATE_AFTER_RING_OPTIONS:
            let options:{[key:string]:string | string[]} = {};
            if(action.payload){
              options["source"] = state.afterRingSelectedOptions["source"] ;
            }
            options["shape"] = state.afterRingSelectedOptions["shape"];
            Object.keys(state.afterRingSelectedOptions).includes('scs') && (options["scs"] = state.afterRingSelectedOptions["scs"]);
            // Object.keys(state.afterRingSelectedOptions).includes('lab') && (options["lab"] = state.afterRingSelectedOptions["lab"]);
            return {
                ...state,
                isReset : true,
                afterRingSelectedOptions : {...options},
                options
            }
        case FilterConstant.SET_REMOVED_MIN_MAX:
            return {
                ...state,
                removedMinMax:{...state.removedMinMax,...action.payload as {[key:string] : boolean}}
            }
        case FilterConstant.RESET_REMOVED_MIN_MAX:
            return {
                ...state,
                removedMinMax:{}
            }
        case FilterConstant.FILTER_INITIAL_STATE:
            return initialFilterState;
        default:
            return state;
    }
};

export default filterReducer;
