import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import debounce from "debounce";
import {FloatingLabelType} from "./floatingLabel";

const useFloatingLabel = (props: FloatingLabelType) => {
    const {isHandView, activeIndex,maxLength,setRingOptions,keyName,value, labelId, setIsEngraving,options, validationRegex, validationErrorMessage} = props ;
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState("");
    useEffect(()=>{
        if(value){
            setInputValue(value);
        }
    },[])

    const debouncedHandleChange = debounce(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            if (newValue.length <= maxLength) {
                let action = "";
                switch (keyName) {
                    case "Engraving":
                        action = "ShankEngraving";
                        break;
                    case "First_Band_Engraving":
                        action = "LeftEngraving";
                        break;
                    case "Second_Band_Engraving":
                        action = "RightEngraving";
                        break;
                    default:
                        break;
                }
                if (action !== "") {
                    window.postMessage({ action, value: newValue });
                }
            }
        },
        1000
    );
    const handleChange = useCallback((event:ChangeEvent<HTMLInputElement>) =>
    {
        const newValue = event.target.value;
        if (validationRegex && !validationRegex.test(newValue)) {
            setError(validationErrorMessage ?? "Invalid input");
            return;
        }
        if (newValue.length <= maxLength) {
            setInputValue(newValue)
            setRingOptions(keyName, newValue);
        }
        setError("");
        debouncedHandleChange(event);
    },[validationRegex, validationErrorMessage, maxLength, setRingOptions, debouncedHandleChange]);
    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(activeIndex === 0 && !isHandView){
            const {Engraving,First_Band_Engraving,Second_Band_Engraving}= options;
            window.postMessage({ action: (Second_Band_Engraving || First_Band_Engraving || Engraving) ? 'SwitchEngraveView' : 'SwitchCameraView' }, '*');
            setIsEngraving(!!(Second_Band_Engraving || First_Band_Engraving || Engraving));
        }
    };
    return {
        handleChange,
        inputValue,
        handleKeyUp,
        error
    }
}
export default useFloatingLabel;
