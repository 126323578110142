import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiRangeSliderProps } from '../../types';

const useInputSlider = (props: MultiRangeSliderProps) => {
  const {
    min,
    max,
    selectedValue,
    handleFilterType,
    options,
    removeFilterKey,
    isReset,
    rangeValue,
    setBackToSelectDiamondPage,
    setRemovedMinMaxOptions,
    isCustomSlider,
    sliderRange = []
  } = props;
  const [value, setValue] = useState<[number | string, number | string]>((selectedValue && selectedValue.length > 0) ? selectedValue : [min, max]);
  const [isLeftFocus, setIsLeftFocus] = useState<boolean>(false);
  const [isRightFocus, setIsRightFocus] = useState<boolean>(false);
  const [isChangeComplete, setIsChangeComplete] = useState(false);
  const [isInputValue, setIsInputValue] = useState(false);
  const [minMaxValue, setMinMaxValue] = useState<[number, number]>([min, max]);
  const [sliderValue, setSliderValue] = useState<[number | string, number | string]>([0, 100]);

  useEffect(() => {
    if (selectedValue && selectedValue.length > 0 && isCustomSlider) {
      setSliderValue([getSliderValue(selectedValue[0]), getSliderValue(selectedValue[1])]);
    }
  }, []);

  const getOriginalValue = (min: number, max: number) => {
    const minValueStep = sliderRange.find(slider => slider.range.max >= (min - 1));
    const maxValueStep = sliderRange.find(slider => slider.range.max >= (max));
    let minValue = 0;
    let maxValue = 0;
    if (minValueStep) {
      const {
        sliderValue: {
          min: minPrice,
          max: maxPrice
        }, range: {
          min: minRange,
          max: maxRange
        }
      } = minValueStep;
      minValue = +minPrice + (((+maxPrice - +minPrice) / (+maxRange - +minRange)) * ((min === 1 ? 0 : min) - +minRange));
    }
    if (maxValueStep) {
      const {
        sliderValue: {
          min: minPrice,
          max: maxPrice
        }, range: {
          min: minRange,
          max: maxRange
        }
      } = maxValueStep;
      maxValue = +minPrice + (((+maxPrice - +minPrice) / (+maxRange - +minRange)) * ((max === 1 ? 0 : max) - +minRange));
    }
    return {
      minValue:(+minValue).toFixed(2),
      maxValue: (+maxValue).toFixed(2)
    }
  }

  const getSliderValue = useCallback((value: number) => {
    if (!sliderRange) return 0;
    const step = sliderRange.find(slider => slider.sliderValue.max >= value);
    if (!step) return value < sliderRange[0].sliderValue.min ? 0 : 100;
    const { sliderValue, range } = step;
    return range.min + ((value - sliderValue.min) / (sliderValue.max - sliderValue.min)) * (range.max - range.min);
  }, [sliderRange]);

  const handleChange = useCallback((val: number | number[]): void => {
    if (Array.isArray(val)) {
      if (isCustomSlider) {
        const { minValue, maxValue } = getOriginalValue(val[0], val[1]);
        setSliderValue(val as [number, number]);
        setValue([minValue, maxValue]);
      } else {
        setValue(val as [number, number]);
      }
    }
  }, [isCustomSlider, getOriginalValue]);

  useEffect(() => {
    if (isChangeComplete) {
      // console.log(min,max,value[0],value[1])
      setMinMaxValue([min, max]);
    }
  }, [isChangeComplete, min, max]);

  useEffect(() => {
    if (!isInputValue) {
      if (isChangeComplete && Object.keys(options).length > 0) {
        setValue([value[0], value[1]]);
        if (isCustomSlider) {
          setSliderValue([getSliderValue(+value[0]), getSliderValue(+value[1])]);
        }
      } else if (selectedValue && selectedValue.length > 0) {
        // setMinMaxValue([min,max])
        setValue([rangeValue?.[0] || selectedValue[0], rangeValue?.[1] || selectedValue[1]] as [number, number]);
        if (isCustomSlider) {
          setSliderValue([getSliderValue(+(rangeValue?.[0] || selectedValue[0])), getSliderValue(+(rangeValue?.[1] || selectedValue[1]))]);
        }
      } else {
        setValue([rangeValue?.[0] || min, rangeValue?.[1] || max]);
        if (isCustomSlider) {
          setSliderValue([getSliderValue(+(rangeValue?.[0] || min)), getSliderValue(+(rangeValue?.[1] || max))]);
        }
      }
      setIsInputValue(false);
    }
  }, [min, max, options, selectedValue]);

  const handleInputChange = useCallback((index: 0 | 1) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/^\$0*/, '');
    let numericValue = inputValue.replace(/[^\d.]/g, '');
    const newValue = [...value] as [number | string, number | string];
    const parts = numericValue.split('.');
    if (parts.length > 1 && parts[1].length > 2) {
      numericValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }
    newValue[index] = isNaN(Number(numericValue)) ? '' : numericValue;
    setValue(newValue);
    if (isCustomSlider) {
      const newSliderValue = [...sliderValue] as [number, number];
      newSliderValue[index] = getSliderValue(+newValue[index]);
      setSliderValue(newSliderValue);
    }
  }, [value, isCustomSlider, getSliderValue, sliderValue]);

  const handleChangeComplete = useCallback((val: number | number[], key: string) => {
    setIsChangeComplete(true);
    if (Array.isArray(val)) {
      const [leftValue, rightValue] = isCustomSlider
        ? [getOriginalValue(val[0], val[1]).minValue, getOriginalValue(val[0], val[1]).maxValue]
        : val;

      if (leftValue !== value[0] || rightValue !== value[1] || document.querySelector('body.tabbing')) {
        handleFilterType(`${leftValue}-${rightValue}`, key);
        if (leftValue === min && rightValue === max) {
          removeFilterKey(key);
          setRemovedMinMaxOptions?.({ [key]: true });
          setIsChangeComplete(false);
        }
      }
    }
    setBackToSelectDiamondPage?.(false);
  }, [handleFilterType, setRemovedMinMaxOptions, removeFilterKey, value, getOriginalValue, isCustomSlider, min, max, setBackToSelectDiamondPage]);

  const handleBlur = useCallback((index: 0 | 1, key: string) => {
    setIsInputValue(true);
    const updatedNumbers: [number | string, number | string] = [...value];
    const currentValue = +value[index];

    switch (true) {
      case currentValue < min :
        updatedNumbers[index] = min;
        break;
      case currentValue > max :
        updatedNumbers[index] = max;
        break;
      case index === 0 && currentValue > +value[1] :
        updatedNumbers[index] = +value[1];
        break;
      case index === 1 && currentValue < +value[0] :
        updatedNumbers[index] = +value[0];
        break;
      default :
        updatedNumbers[index] = currentValue;
    }

    if (updatedNumbers[index] === value[index]) return;

    handleFilterType(`${updatedNumbers[0]}-${updatedNumbers[1]}`, key);

    if (updatedNumbers[0] === min && updatedNumbers[1] === max) {
      removeFilterKey(key);
      const data = { [key]: true };
      setRemovedMinMaxOptions?.(data as { [key: string]: boolean });
    }

    setValue(updatedNumbers);
    if (isCustomSlider) {
      const [updatedMinValue, updatedMaxValue] = [
        getSliderValue(+updatedNumbers[0]),
        getSliderValue(+updatedNumbers[1])
      ];
      setSliderValue([updatedMinValue, updatedMaxValue]);
    }
    setIsLeftFocus(false);
    setIsRightFocus(false);
  }, [value, min, max, handleFilterType, removeFilterKey, setRemovedMinMaxOptions, isCustomSlider, getSliderValue]);
  // reset all filter value when click reset button
  useEffect(() => {
    if (isReset) {
      setValue([rangeValue?.[0] || min, rangeValue?.[1] || max]);
      if (isCustomSlider) {
        setSliderValue([getSliderValue(+(rangeValue?.[0] || min)), getSliderValue(+(rangeValue?.[1] || max))]);
      }
    }
  }, [isReset]);

  const minMaxSliderValue = useMemo(() => {
    return isCustomSlider
      ? [getSliderValue(isChangeComplete ? minMaxValue[0] : min), getSliderValue(isChangeComplete ? +minMaxValue[1] : max)]
      : [isChangeComplete ? minMaxValue[0] : min, isChangeComplete ? minMaxValue[1] : max];
  }, [isCustomSlider, getSliderValue, isChangeComplete, value, min, max, minMaxValue]);

  return {
    value,
    handleChange,
    isLeftFocus,
    isRightFocus,
    handleLeftInputRange: handleInputChange(0),
    handleRightInputRange: handleInputChange(1),
    handleChangeComplete,
    handleLeftFocus: () => setIsLeftFocus(true),
    handleBlur,
    handleRightFocus: () => setIsRightFocus(true),
    minValue: minMaxSliderValue[0],
    maxValue: minMaxSliderValue[1],
    handleContextMenu: (event: React.MouseEvent) => event.preventDefault(),
    sliderValue
  };
};
export default useInputSlider;
